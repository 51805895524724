@media  (min-width: 320px) and (max-width: 767px){

    /* pink pg */

    .hmLeft {
        width: 100%;
    }

    .hmTitle {
        font-size: 1.4rem;
    }

    .hmDesc {
        width: 100%;
        font-size: 0.9rem;
        margin-bottom: 20px;
    }
    .lm {
        font-size: 0.8rem;
        padding: 6px;
    }

    .hmm {
        align-items: center;
        /* height: 100vh; */
        height: calc(100vh - 80px);
        flex-direction: column-reverse;
        /* padding-top: 130px; */
    }

    .hmain1 {
        /* padding-top: 285px; */
        padding-top: 220px;
    }
    
    /* slider */

    .left, .rgt {
        padding: 10px 10px;
    }

    .left img, .rgt img {
        width: 10px;
    }

    .imgtxt{
        gap: 4%;
        height: 15vh;
        /* border: 1px solid red; */
    }

    .imgtxt .btxt, .imgtxtwd .btxt, .imgtxtai .btxt, .imgtxtam .btxt{
        width: 60%;
        /* padding-bottom: 30px; */
    }

    .imgtxtwd .brain img {
        margin-left: 0px;
    }

    .btxt {
        font-size: 0.5rem;
        height: 10vh;
    }

    .imgtxt .brain img, .imgtxtwd .brain img, .imgtxtai .brain img, .imgtxtam .brain img{
        width: 100%;
        /* border: 1px solid black; */
    }

    .brain{
        width: 40%;
        /* border: 1px solid blue; */
    }

    .addToCart button {
        padding: 2px 8px;
        border-radius: 15px;
        font-size: 0.5rem;
    }

    .addToCart {
        margin-bottom: 10px;
    }

    .sliderContent {
        height: 25vh;
        align-items: center;
    }

    .sliderWindow {
        height: 25vh;
    }

    .hSBtns {
        width: 100%;
    }

    .hSHeading {
        width: 90%;
        margin: 15px auto 15px auto;
        /* font-size: 0.5rem; */
    }

    .sliderMain {
        width: 90%;
        margin: 0;
    }

    .homeSlider {
        height: max-content;
    }

    .hSHeading h1, .osHeading h1, .pbHeading h1 {
        font-size: 1.2rem;
    }

    .ourStrategy, .popularBrands {
        margin-top: 0px;
        justify-content: space-evenly;
        height: 50vh;
    }

    .popularBrands{
        justify-content: flex-start;
        height: 35vh;
    }

    .osImg {
        height: max-content;
    }

    .osImg img {
        width: 95%;
    }

    .pbImg img {
        width: 95%;
    }
    .allfs {
        flex-direction: column;
    }



    /* NAVBAR */

    @keyframes fade-in-out {
        0%, 100% { opacity: 0; }
        10%, 90% { opacity: 1; }
      }
      
      .navbar-toggler {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        z-index: 1002;
        color: black;
      }
      
      .navComp {
        list-style: none;
        display: flex;
        gap: 20px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        background-color: white;
        transition: height 0.3s ease-in-out;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1000;
      }
      
      .navComp.open {
        height: calc(100vh - 80px); 
      }
      
      .navComp.open li {
        margin-bottom: 20px;
      }
      
        .navbar-toggler {
          display: block;
          color: gray;
        }
      
        .navComp {
          display: flex;
        }
     

      /* services */

      .smain1 {
        padding: 0 0px;
        flex-direction: column;
    }

    .allrdm {
        width: 250px;
        margin-top: 100px;
    }

    .servHead h1 {
        font-size: 1.5rem;
    }

      .red-ani, .red-ani2, .red-ani3{
        height: 70px;

      }

      .rdm2 {
        /* border: 1px solid white; */
        margin: 15px 0;
    }

    .servHead h2 {
        font-size: 0.9rem;
        font-weight: 500;
        margin: 10px 0;
    }

    .servHead {
        /* border: 1px solid white; */
        width: 80%;
    }

    .wwc h1 {
        /* font-size: 1rem; */
        padding: 10px 10px;
    }

    .crtns a {
        font-size: 1rem;
    }

    .new {
        margin: 0 5px;
        font-size: 0.6rem;
        padding: 3px 6px;
    }

    .prjts {
        height: 150px;
    }

    .loadMore {
        font-size: 0.5rem;
        margin-left: 0px;
    }

    .prjt-hide {
        font-size: 0.5rem;
        padding: 0 5px;
    }

    .smain2 .wwc {
        padding-bottom: 50px;
    }

    .quality {
        width: 95%;
        margin: 10px auto;
        flex-direction: column;
        align-items: center;
    }

    .quality img {
        width: 250px;
    }

    .quality p {
        width: 85%;
        padding: 5px 0;
        margin: 0 0px;
        font-size: 0.7rem;
    }

    /* contact */

    .c2 {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
    

    .c3 {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
    }

    .c2 .cn {
        width: 100%;
    }

    .c2 .yn {
        width: 100%;
    }

    .c3 input {
        width: 100%;
    }

    .c1 {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
    }
    
    .c1 select {
        width: 100%;
    }

    .oro-form .c1 select {
        width: 100%;
    }

    .wwc h1 {
        font-size: 0.7rem;
        /* display: none; */
    }

    .all-feats{
        display: none;
    }

    .contact-form input, .contact-form textarea {
        width: 100%;
    }

    .contact-form input::placeholder , .contact-form textarea::placeholder{
        font-size: 0.8rem;
    }

    .gaq-btn {
        margin: 20px auto;
    }

    .contact-form {
        /* border: 1px solid white; */
        width: 80%;
        padding: 0px;
        /* box-shadow: 0px 5px 10px 0px #080808; */
    }

    .allWays {
        /* border: 1px solid white; */
        padding: 0px 0;
    }

    
    .c1 select {
        /* width: 25%; */
        font-size: 0.8rem;
        padding: 10px 15px;
    }

    .contact-form input, .contact-form textarea {
        padding: 15px 15px;
        /* color: #b7b7b7; */
        font-size: 0.8rem;
    }
/* 
    .contact-form textarea{
        padding: 15px 15px;
        font-size: 0.8rem;
    } */

    .gaq {
        font-size: 0.8rem;
        padding: 15px;
        width: 100%;
    }
    .wtsp {
        margin: 20px 0 20px;
    }

    .oro p {
        font-size: 0.8rem;
    }

    .wtsp button {
        padding: 8px;
        width: 50%;
        font-size: 0.7rem;
    }

    .contHead {
        align-items: center;
    }

    .meetCheck label {
        justify-content: center;
        font-size: 0.7rem;
        margin-left: 15px;
    }

    .meetCheck input{
        width: 20px;
    }

    .caw {
        width: 75%;
        font-size: 0.8rem;
    }

    .c3 .phn {
        width: 100%;
    }

    .sc h1{
        font-size: 0.9rem;
    }

    /* SOLUTIONS */

    .ssc {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ssc-d {
        padding: 5px;
        font-size: 0.6rem;
        margin: 0 5px;
    }

    .gi-back, .glitch-image{
        width: 100%;
    }

    .starter {
        margin-right: 20px;
    }

    .starter-desc {
        /* border: 1px solid white; */
        margin: 10px 0;
        font-size: 0.4rem;
    }

    .starter h1{
        font-size: 0.68rem;
        padding: 5px 0;
    }

    .ac-now {
        padding: 5px 10px;
        font-size: 0.6rem;
    }

    .sb-icons {
        width: 100%;
        align-items: center;
    justify-content: center;
    }

    .sb-icons img {
        width: 25px;
    }

    .sbi {
        width: 40px;
        height: 40px;
    }

    .sol-h2 {
        font-size: 0.8rem;
    }

    .solb {
        width: 85%;
        flex-direction: column;
        justify-content: center;
        font-size: 0.6rem;
    }

    .solb img {
        width: 250px;
        margin-right: 0px;
        margin-bottom: 29px;
    }

    .solmain2 h1{
        font-size: 0.6rem;
        text-align: center;
    }

    .solmain h1 {
        font-size: 2rem;
        width: 80%;
    }

    .solmain3 {
        height: 58vh;
    }

    .floor {
        bottom: -50px;
    }

    .footer{
        margin-top: 0px;
    }

    /* ...........RAINBOW............... */

    .rainoutimg {
        width: 100%;
    }

    .rainContent {
        border-radius: 15px;
        width: 70%;
        padding: 5px;
    }

        .raininCon {
            width: 80%;
            padding: 10px 15px 10px 15px;
            margin-top: -120px;
            gap: 15px;
        }

    .logohead p {
        font-size: 0.6rem;
    }

    .logohead {
        gap: 15px;
    }

    .logopara p {
        width: 85%;
        font-size: 0.4rem;
    }
    .logobtns .learnmore, .signup {
        border-radius: 5px;
        gap: 5px;
        font-size: 0.4rem;
        padding: 4px 6px;
    }
}

@media (min-width:320px) and (max-width:424px) {
    .homeHero {
        width: 100vw;
        right: -55px;
        /* top: 35px; */
        top: -60px;
    }   

    .servHead {
        /* border: 1px solid white; */
        width: 80%;
    }
}

@media (min-width:375px) and (max-width:423px){
    .servHead {
        /* border: 1px solid white; */
        width: 70%;
    }

    .solmain h1 {
        font-size: 2.4rem;
    }

    /* RAINBOW */

    .raininCon {
        padding: 10px 15px 10px 15px;
        margin-top: -140px;
        gap: 15px;
    }

    .logohead p {
        font-size: 0.7rem;
    }

    .logopara p {
        font-size: 0.5rem;
    }

    .learnmore img, .signup img {
        width: 10%;
    }

    .logobtns .learnmore, .signup {
        border-radius: 5px;
        gap: 5px;
        font-size: 0.4rem;
        padding: 6px 0px;
    }

    .logobtns {
        gap: 20px;
    }
}

@media (min-width:424px) and (max-width:460px) {

    .homeHero {
        width: 100vw;
        right: -60px;
        /* top: 20px; */
        top: -20px;
    }

    .hmain1 {
        padding-top: 315px;
    }

    .servHead {
        /* border: 1px solid white; */
        width: 60%;
    }

    .solmain h1 {
        font-size: 2.8rem;
       
    }

    /* rainbow */

    .raininCon {
        width: 80%;
        padding: 10px 15px 10px 15px;
        margin-top: -155px;
        gap: 20px;
        height: auto;
    }

    .logohead p {
        font-size: 0.8rem;
    }

    .logopara p {
        width: 75%;
        font-size: 0.5rem;
    }

    .logobtns .learnmore, .signup {
        border-radius: 5px;
        gap: 5px;
        font-size: 0.4rem;
        padding: 6px 2px;
    }

    
}

@media (min-width:461px) and (max-width:550px) {

    .homeHero {
        width: 100vw;
        right: -60px;
        top: 20px;
    }

}

@media (min-width:740px) and (max-width:767px) {

    /* RAINBOW */

.rainoutimg {
    width: 70%;
}

.rainContent {
    border-radius: 28px;
    width: 50%;
}

.raininCon {
    width: 80%;
    /* border: 1px solid black; */
    padding: 15px 20px 15px 20px;
    margin-top: -205px;
    gap: 20px;
}

.logohead p {
    font-size: 1.2rem;
}

.logohead {
    gap: 20px;
}

.logopara p {
    width: 80%;
    font-size: 0.7rem;
}

.logobtns .learnmore, .signup {
    border-radius: 10px;
    font-size: 0.6rem;
    padding: 8px 4px;
}

.logobtns {
    gap: 25px;
}

.learnmore img, .signup img {
    width: 12%;
}
    
}

@media (min-width:768px) and (max-width:1023px){
    .navComp {
        width: 45%;
    }

    a {
        font-size: 0.8rem;
    }

    .hmTitle {
        font-size: 1.6rem;
    }

    .hmDesc {
        width: 335px;
        font-size: 0.8rem;
    }

    .hmain1 {
        width: 85%;
    }

    .lm {
        padding: 10px;
        font-size: 0.9rem;
    }

    .homeHero {
        width: 93vw;
        height: 100vh;
        right: -160px;
        /* top: -145px; */
        top: -160px;
    }

    .hSHeading h1, .osHeading h1, .pbHeading h1 {
        font-size: 1.8rem;
    }

    .hSHeading {
        margin: 30px auto;
    }

    .sliderMain {
        width: 95%;
        margin: 0;
    }

    .hSBtns {
        width: 100%;
    }

    .left img, .rgt img {
        width: 20px;
    }

    .left, .rgt {
        padding: 15px 16px;
    }

    .btxt {
        /* width: 300px; */
        font-size: 0.8rem;
        padding-bottom: 30px;
        height: 130px;
        /* width: 220px; */
    }

    .brain img {
        width: 160px;
        margin-top: -105px;
    }

    .imgtxtwd .brain img {
        width: 245px;
        margin-top: -95px;
        margin-left: -30px;
    }

    .imgtxtai .brain img {
        width: 205px;
        margin-top: -126px;
    }

    .imgtxtam .brain img {
        width: 300px;
        margin-top: -60px;
    }

    .imgtxt .btxt, .imgtxtwd .btxt, .imgtxtai .btxt, .imgtxtam .btxt{
        width: 40%;
        /* padding-bottom: 30px; */
    }

    .addToCart button {
        padding: 4px 10px;
        border-radius: 15px;
    }

    .ourStrategy, .popularBrands {
        margin-top: 0px;
    }

    .footerMain {
        width: 90%;
    }

    .f h4 {
        font-size: 0.9rem;
    }

    .red-ani, .red-ani2, .red-ani3{
        height: 70px;
    }

    .allrdm {
        width: 250px;
    }

    .smain1 {
        gap: 30px;
    }

    .servHead h1 {
        font-size: 2.4rem;
        line-height: 50px;
    }

    .servHead h2 {
        font-size: 1.3rem;
        margin: 40px 0
    }

    .rdm2 {
        /* border: 1px solid white; */
        margin: 20px 0;
    }

    .prjts {
        height: 380px;
    }

    .crtns a {
        font-size: 1.3rem;
    }

    .quality {
        width: 90%;
        margin: 0px auto;
    }

    .quality p {
        /* width: 420px; */
        padding: 10px 0;
        margin: 0 10px;
        font-size: 0.7rem;
    }

    .quality img {
        width: 300px;
    }

    .smain2 .wwc {
        padding-bottom: 50px;
    }

    /* RAINBOW */

.rainoutimg {
    width: 70%;
}

.rainContent {
    border-radius: 28px;
    width: 50%;
}

.raininCon {
    width: 80%;
    /* border: 1px solid black; */
    padding: 15px 20px 15px 20px;
    margin-top: -205px;
    gap: 20px;
}

.logohead p {
    font-size: 1.2rem;
}

.logohead {
    gap: 20px;
}

.logopara p {
    width: 80%;
    font-size: 0.7rem;
}

.logobtns .learnmore, .signup {
    border-radius: 10px;
    font-size: 0.6rem;
    padding: 8px 4px;
}

.logobtns {
    gap: 25px;
}

.learnmore img, .signup img {
    width: 12%;
}



/* contact */

.wwc h1 {
    font-size: 1.5rem;
}

.af p {
    font-size: 0.7rem;
}

.allWays {
    /* border: 1px solid white; */
    padding: 50px 0;
}

.oro p {
    font-size: 0.7rem;
    margin: 15px 0;
}

.contact-form textarea{
    width: 100%;
}

.oro-yellow {
    margin: 20px 0;
    font-size: 0.7rem;
}

.gaq {
    font-size: 0.8rem;
    padding: 12px;
    width: 200px;
    border-radius: 35px;
}

.wtsp button {
    padding: 15px;
    width: 250px;
    border-radius: 40px;
    font-size: 0.8rem;
}

.contact-form input, .contact-form textarea {
    font-size: 0.8rem;
    padding: 10px 10px;
}

.contact-form input::placeholder , .contact-form textarea::placeholder{
    font-size: 0.8rem;
}

.meetCheck label {
    font-size: 0.8rem;
}

.caw {
    font-size: 0.8rem;
    margin: 15px auto;
}

.c1 select {
    width: 48%;
    font-size: 0.8rem;
    padding: 10px 15px;
}

.contact-form {
    width: 95%;
}

.c2 .cn {
    width: 61%;
}

.oro-form .c1 select {
    width: 38%;
}

.aw h1 {
    width: 100%;
}

/* solutions */

.solb {
    width: 85%;
    font-size: 0.8rem;
}

.solb img {
    width: 300px;
    margin-right: 50px;
}

.solmain h1 {
    font-size: 3.2rem;
}

.sol-h2 {
    font-size: 1.5rem;
}

.ssc-d {
    font-size: 1rem;
    margin: 0 20px;
}

.ssc {
    width: 100%;
    /* margin: auto; */
    align-items: center;
    justify-content: center;
}

.gi-back, .glitch-image{
    width: 100%;
}  

.starter-desc {
    /* border: 1px solid white; */
    margin: 20px 0;
    font-size: 0.8rem;
}

.starter {
    margin-right: 50px;
}

.solmain3{
    height: 85vh;
}

.footer{
    margin-top: 100px;
}

}

@media (min-width: 1024px) and (max-width: 1439px) {
    /* Navbar */
    .navComp {
      width: 45%;
    }
  
    /* Pink Page */
  
    .hmTitle {
      font-size: 2rem;
  }
  
    .hmDesc {
      
      width: 385px;
      font-size: 1rem;
  }
  
  .lm {
      font-size: 1rem;
  }
  
  .homeHero {
      width: 72vw;
      height: 85vh;
      right: -165px;
      top: -61px;
  }
  
  /* homeslider */
  
  .hSHeading {
      /* margin: 10px auto; */
      /* margin: 25px auto 0 auto; */
  }

  .red-ani, .red-ani2, .red-ani3{
    height: 70px;
  }

  .allrdm {
    width: 250px;
}

.servHead h2 {
    font-size: 2rem;
}

.servHead h1 {
    font-size: 3.6rem;
}

.smain1{
    justify-content: space-between;
    padding: 0 60px;
} 


.prjts {
    height: 400px;
}

.quality p {
    margin: 0 30px;
}

.quality img {
    height: 500px;
}

.quality {
    width: 95%;
}
  

/* solutions */

.solmain h1 {
    font-size: 5.5rem;
    width: 70%;
}

.solb {
    width: 90%;
}

.solb img {
    margin-right: 50px;
}

.ssc {
    display: flex;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.spDesc .starter{
    margin-right: 0px;
    right: -70px;
}

.starter {
    width: max-content;
    margin-right: 90px;
}

.solmain3 {
    height: max-content;
}

.footer {
    margin-top: 100px;
}


  /* contact */

  .contact-form textarea {
    width: 100%;
}

.contact-form {
    width: 90%;
}

.sc h1 {
    font-size: 2rem;
}

.c2 .cn {
    width: 60%;
}

.oro-form .c1 select {
    width: 37%;
}

.contact-form input, .contact-form textarea {
    padding: 15px 15px;
}

.c1 select {
    width: 48%;
}

.c3 input {
    width: 30.5%;
}

/* RAINBOW */

.rainoutimg {
    width: 60%;
}

.rainContent {
    border-radius: 30px;
    width: 42%;
}

.raininCon {
    width: 80%;
    /* border: 1px solid black; */
    padding: 15px 25px 15px 25px;
    margin-top: -235px;
    gap: 30px;
}

.logohead p {
    font-size: 1.3rem;
}

.logohead {
    gap: 20px;
}

.logopara p {
    width: 80%;
    font-size: 0.8rem;
}

.logobtns .learnmore, .signup {
    border-radius: 10px;
    font-size: 0.7rem;
    padding: 10px 12px;
}

.learnmore img, .signup img {
    width: 12%;
}

}

@media (min-width:1440px) and (max-width:1500px) {
    .contact-form textarea {
        /* width: calc(100% - 40px); */
        width: 100%;
    }

    .contact-form {
        width: 80%;
    }

    .c2 .cn {
        width: 61%;
    }

    .oro-form .c1 select {
        width: 40%;
    }

    .c3 input {
        width: 34.5%;
    }

    .c1 select {
        width: 49%;
    }

    /* SOLUTIONS */

    .solmain h1 {
        font-size: 6rem;
    }


}

@media (min-width:2560px) {

    .contact-form {
        width: 80%;
    }

    .oro-form .c1 select {
        width: 35%;
    }

    .scun {
        height: 4px;
    }

    .scWhite {
        width: 5px;
        height: 4px;
    }

    .c3 input {
        width: 41%;
    }

    .c1 select {
        width: 49.2%;
    }

    .c2 .cn {
        width: 61.5%;
    }

    .oro-form .c1 select {
        width: 44%;
    }

    .contact-form textarea {
        /* width: calc(100% - 40px); */
        width: 100%;
    }

    /* SOLUTIONS */

    .solmain h1 {
        font-size: 9rem;
        width: 50%;
    }

    .ssc-d {
        z-index: 10;
        position: relative;
    }

    /* Rainbow */

    .raininCon {
        width: 80%;
        /* border: 1px solid black; */
        padding: 50px 60px 60px 60px;
        margin-top: -540px;
        gap: 60px;
    }

    .logohead p {
        font-size: 2.8rem;
    }

    .logopara p {
        width: 80%;
        font-size: 1.8rem;
    }

    .logobtns .learnmore, .signup {
        border-radius: 20px;
        gap: 15px;
        font-size: 1.5rem;
        padding: 24px 35px;
    }

    .rainContent {
        border-radius: 60px;
        padding: 10px;
    }

    .logohead {
        gap: 40px;
    }
    
}
  