.exploreCSMain {
    /* border: 1px solid black; */
    width: 100%;
    margin-top: 15vw;
  }
  
  .hSHeading {
    width: 88%;
  }
  
  .sliderMainCS {
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    margin-top: 5%;
    overflow-x: hidden; 
  }
  
  .exploreBtns {
    /* border: 1px solid black; */
    position: absolute;
    width: 100%;
    /* text-align: left; */
    padding-left: 15%;
    cursor: pointer;
    z-index: 1;
  }

.ogimg{
  background-color: #fff8c0;
    padding: 20px 21px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    position: relative;
    z-index: 2;
}

  .exploreBtns img{
    width: 25px;
  }
  
  .sliderWindow2 {
    /* border: 1px solid red; */
    width: 72%;
    height: 375px;
    overflow-x: hidden; 
    position: relative;
    padding: 5px;
  }
  
  /* .slideRed {
    border: 1px solid black;
    height: 100%;
    width: 270px; 
  } */
  
  .exploreSlide {
    display: flex;
    gap: 20px;
    height: 100%;
    width: max-content; 
    transition: margin-left 0.5s ease; 
    position: relative;
    z-index: 3;
  }


  /* ..................... */

  .RfSlide{
    /* border: 1px solid black; */
    height: 100%;
    width: 270px;
    /* margin: 0 20px; */
    padding: 25px 15px;
    border-radius: 15px;
    
}

.starterSL{
    background-image: linear-gradient(180deg, #148000 0%, #054000 100%);
    color: white;
}
.social{
    background-image: linear-gradient(180deg, #004976 0%, #002b44 100%);
    color: white;
}
.motion{
    background-image: linear-gradient(180deg, #807100 0%, #403100 100%);
    color: white;
}
.trend{
    background-image: linear-gradient(180deg, #800000 0%, #400000 100%);
    color: white;
}
.support{
    background-image: linear-gradient(180deg, #c4c4c4 0%, #1f1f1f 100%);
    color: white;
}


.starterSL h2{
    color: #1AEB5F;
}
.social h2{
    color: #00b1ff;
}
.motion h2{
    color: #FFD100;
}
.trend h2{
    color: #FF2900;
}
.support h2{
    color: #161616;
}

.RfSlide p{
    font-size: .9rem;
    /* border: 1px solid black; */
    width: 82%;
}

.RfSlide h2{
    font-size: 1.1rem;
    line-height: 17px;
}

.RfSlide h1{
    /* font-size: 1.7rem; */
    line-height: 30px;
}

.explore{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    box-shadow: 1px 1px 5px 2px #cecece;
    cursor: pointer;
}

.explore h1{
    text-align: center;
    line-height: 35px;
    color: black;
}

.explore img{
    width: 25px;
    transform: rotate(180deg);
}

.rfcImg{
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 150px;
    z-index: 1;
}

.rfcImg img:nth-child(1)
{
    width: 100%;
    height: 150px;
    object-fit: contain;
}
.support .rfcImg img:nth-child(1)
{
    width: 100%;
    height: 120px;
    object-fit: contain;
}

.social .rfcImg img:nth-child(2)
{
    width: 22%;
    position: absolute;
    transform: translate(0, 0px);
    aspect-ratio: 2/2;
}
.rfcImg img:nth-child(2)
{
    width: 22%;
    position: absolute;
    transform: translate(-2px, 5px);
    aspect-ratio: 2/2;
}
.support .rfcImg img:nth-child(2)
{
    width: 22%;
    position: absolute;
    transform: translate(10px, 0px);
    aspect-ratio: 2/2;
}


/* SLIDER MEDIA QUERY.......... */


@media (min-width:320px) and (max-width:767px) {
    
  .sliderWindow2 {
    width: 95%;
      margin-left: 5%;
    padding: 5px;
}

    .sliderMainCS {
      justify-content: center;
    }

    .exploreBtns{
      display: none;
    }
    
    .sliderWindow2{
      overflow-x: scroll;
    }

    .sliderWindow2::-webkit-scrollbar{
      display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {

    .sliderWindow2 {
      width: 68%;
      padding: 5px;
  }

  .exploreBtns{
    padding-left: 12%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    

    .sliderWindow2 {
      width: 80%;
      padding: 5px;
  }

  .exploreBtns{
    padding-left: 6%;
  }

}

@media (min-width:1750px) {

.exploreBtns{
display: none;
}

.sliderMainCS {
  justify-content: center;
}

.sliderWindow2 {
  width: max-content;
  padding: 5px;
}
  
}
  