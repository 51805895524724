.contact{
    /* height: 100vh; */
    /* padding-top: 80px; */
}

.cntImg{
    width: 100%;
}

.contHead{
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.sc{
    /* border: 1px solid white; */
    width: max-content;
    position: relative;
    margin: auto;
}

.sc h1{
    color: white;
    font-size: 2.5rem;
    padding: 0px 50px 20px 50px;
}

.wtsp{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0 50px;
}

.wtsp button{
    border: 1px solid #5f5f5f;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 20px;
    width: 300px;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.wtsp button:hover{
    box-shadow: 0px 3px 10px 0px #080808;
}

.wtsp img{
    margin-left: 10px;
    width: 20px;
}

.scWhite {
    background-color: white;
    box-shadow: 0px 0px 15px 0px white;
    width: 7px;
    height: 5px;
    position: absolute;
    left: 0;
    animation: moveWhite 5s ease-in-out infinite;
}

@keyframes moveWhite {
    0%{
        left: 0;
        box-shadow: 0px 0px 0px 0px white; 
    }

    15%{
        box-shadow: 0px 0px 20px 1px white;
    }

    50% {
        left: 100%; 
        box-shadow: 0px 0px 0px 0px white;
    }

    65%{
        box-shadow: 0px 0px 20px 1px white;
    }


    100%{
        left: 0;
        box-shadow: 0px 0px 0px 0px white; 
    }
}


.scun{
    background-image: linear-gradient(90deg, #FFFFFF4D 0%, #FF3D3D 100%);
    width: 100%;
    height: 5px;
}

.allWays{
    /* border: 1px solid white; */
    padding: 100px 0;
}

.oro{
    /* border: 1px solid white; */
    width: 80%;
    margin: 30px auto;
}

.oro h1{
    padding: 0;
}

.oro p{
    color: white;
    font-size: 1rem;
    margin: 20px 0;
}

.oro .otv{
    color: #3DB4FF;
}

/* .oro-form .c1 select{
    width: 30%;
} */

.oro-form .c1 select {
    width: 39%;
}

.oro-yellow{
    color: #F0DE00;
    margin: 20px 0;
    font-size: 0.8rem;
    font-weight: 400;
}

.contact-form{
    /* border: 1px solid white; */
    width: 70%;
    margin: auto;
    color: white;
    padding: 20px;
    /* box-shadow: 0px 5px 10px 0px #080808; */
}

.contact-form input, .contact-form textarea{
    background-color: transparent;
    border: 2px solid #5f5f5f;
    /* padding: 20px 20px; */
    padding: 15px 15px;
    color: #b7b7b7;
    font-size: 1rem;

}
.contact-form select, .contact-form option{
    -webkit-appearance: none; /* Safari */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard */
}
.c1{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.c1 select{
    /* width: 25%; */
    width: 48.5%;
    font-size: 1rem;
    padding: 15px 20px;
    background-color: #1C1C1C;
    color: #b7b7b7;
    box-shadow: 0px 4px 5px 0px #080808;
    border: none;
}

::placeholder{
    color: #b7b7b7;
    font-size: 1rem;
}

.c2{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .c2 .cn{
    width: 53%;
} */

.c2 .cn {
    width: 60.5%;
}

.c2 .yn{
    width: 37%;
}

.c3{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

/* .c3 input{
    width: 30%;
} */

.c3 input {
    width: 33%;
}

.c3 .phn{
    border: 2px solid #5f5f5f;
    padding-left: 15px;
}

.PhoneInput input{
    border: none;
}

.c3 .PhoneInputCountryIcon, .c3 .PhoneInputCountrySelectArrow{
    margin-right: 5px;
}

.contact-form textarea {
    /* width: calc(100% - 40px); */
    width: 100%;
}

.meetCheck{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: max-content;
    margin: 20px auto;
    /* border: 1px solid white; */
}

.meetCheck label{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 1rem;
    color: #b7b7b7;
    margin-left: 15px;
}

.meetCheck img{
    width: 20px;
    margin-left: 15px;
}

input[type=checkbox]{
    width: 20px;
    height: 20px;
    background-color: #FA9E57;
    accent-color: red;
}

.checkbox {
    border: 1px solid white;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    background-color: #1c1c1c;
    cursor: pointer;
    transition: 0.2s ease-in;
  }

  .checkTsp{
    background-color: transparent;
    border: 1px solid white;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: 0.2s ease-in;
  }
  
  .checkbox:hover {
    background-color: var(--rfRed);
    box-shadow: inset 0px 0px 20px 0px #1c1c1c;
  }

  .gaq-btn{
    text-align: center;
    /* margin-top: 15px; */
    /* border: 1px solid red; */
    width: max-content;
    margin: auto;
    border-radius: 40px;
    box-shadow: 0px 5px 10px 0px #080808;    
    cursor: pointer;
  }
  
  .gaq{
    border: 1px solid white;
    border: none;
    font-size: 1rem;
    box-shadow:inset 0px 0px 5px 0px white;
    padding: 15px;
    width: 250px;
    background-color: transparent;
    color: white;
    border-radius: 40px;
    cursor: pointer;
  }

  .gaq:hover{
    box-shadow:inset 0px 0px 7px 0px white;
  }

  .aw .aw-careers{
    padding-bottom: 0;
  }
  
.caw{
    color: white;
    /* padding: 20px 50px; */
    width: 80%;
    margin: 20px auto;
}

.caw a{
    text-decoration: underline;
}

.aw .wtsp{
    /* border: 1px solid white; */
    align-items: flex-start;
    margin: 0;
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

.aw h1{
    width: 80%;
    margin: auto;
}

.oro h1{
    width: 100%;
}