/* HomeSlider.css */
.homeSliderMain {
  width: 100%;
  margin: auto;
}

.homeSlider{
  width: 100%;
  /* margin: auto; */
  height: 100vh;
  height: calc(100vh - 80px);
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hSHeading {
  width: 83%;
  /* font-family: 'Montserrat', sans-serif; */
  margin: 50px auto;
  font-weight: normal;
}

.hSHeading h1, .osHeading h1, .pbHeading h1{
  font-weight: 500;
}

.hSBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 98%;
}

.hSBtns .rgt img{
 transform: rotate(180deg);
}

.sliderMain {
  position: relative;
  width: 88%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderWindow {
  /* width: 1000px; */
  width: 85%;
  overflow: hidden;
  /* border: 1px solid black; */
}

.left, .rgt{
  background-color: #fff8c0;
  padding: 20px 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left img, .rgt img {
  border: none;
  width: 25px;
  cursor: pointer;
}

.addToCart {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 90%;
  margin-bottom: 35px;
}

.addToCart button {
  background: transparent;
  border: 1px solid #696969;
  padding: 7px 18px;
  border-radius: 18px;
  cursor: pointer;
}

.sliderContent{
  /* width: 100%; */
}

.sContentOne {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: 1000px; */
  width: 100%;
  flex-shrink: 0;
  /* border: 1px solid red; */
}

.sContentOne h1 {
  font-size: 6.2rem;
}

.scImg{
  width: 90%;
}

.imgtxt {
  width: 90%;
  display: flex;
  align-items: center;
  height: 230px;
  /* gap: 125px; */
  gap: 12%;
}

.imgtxtwd{
  /* gap: 10px; */
  gap: 0.96%;
}

.imgtxtai{
  width: 82%;
  /* gap: 30px; */
  gap: 2.88%;
}

.imgtxtam{
  width: 99%;
  /* gap: 0px; */
  gap: 0%;

}

.brain img {
  width: 250px;
  margin-top: -40px;
}

.imgtxtwd .brain img{
  width: 370px;
  margin-top: -35px;
  margin-left: -39px;
}

.imgtxtai .brain img{
width: 290px;
margin-top: -100px;
}

.imgtxtam .brain img{
  width: 400px;
  margin-top: -25px;
}

.btxt {
  width: 300px;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 150px;
  /* border: 1px solid black; */
}

.btxt span{
  animation: blink 0.5s infinite;
}

@keyframes blink {
  
  0%, 100% {
      color: transparent;
  }
  50% {
      color: black;
  }
}

.imgtxtwd .btxt{
width: 295px;
padding-bottom: 0px;
}

.imgtxtai .btxt{
  padding-bottom: 0px;
}

.sliderContent {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.exploreServicesMain{
  display: flex;
  align-items: center;
  justify-content: center;
}

.exploreServices{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  font-size: 1.2rem;
  padding: 10px 15px 10px 30px;  
  border: none;
  border-bottom: 2px solid #FF3D3D;
  margin: 50px 0 50px 0;
  width: max-content;
}

.exploreServices img{
  width: 20px;
}

.ourStrategy, .popularBrands{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  /* border: 1px solid black; */
  margin-top: 100px;
}

.popularBrands{
  height: 80vh;
  justify-content: flex-start;
 gap: 70px;
 margin-top: 100px;
}

.osHeading{
  width: 83%;
  font-weight: normal;
  /* border: 1px solid black; */
}

.pbHeading{
  width: 85%;
  /* margin: 70px auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  /* border: 1px solid black; */
}

.pbImg, .osImg{
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
}

.pbImg img{
  width: 73%;
}

.osImg{
  margin: 0px 0px;
  height: 75%;
}

.osImg img{
  width: 82%;
}
