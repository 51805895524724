.lsMain{
    border: 1px solid black;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginSignup{
    /* border: 1px solid black; */
    height: 50%;
    width: 30%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 0px lightgray;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
}

.loginSignup h1{
    /* border: 1px solid black; */
    text-align: center;
    width: 100%;
}

.lsep{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-radius: 5px;
    /* margin-top: 10px; */
}

.loginSignup .lp{
    width: 100%;
    /* border: 1px solid black;   */
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    height: 50%;
}

.lsep label{
    background-color: white;
    position: absolute;
    left: 10px;
}

.lsep input{
    /* border: 1px solid gray; */
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    /* box-shadow: 3px 3px 5px 0 lightgray; */
}

.loginSignup button{
    border: 1px solid var(--rfRed);
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    background-color: var(--rfRed);
    margin: 0px auto;
    cursor: pointer;
    margin-top: 10px;
}

.loginSignup p{
    font-size: 0.8rem;
    font-weight: bold;
    color: black;
}

.loginSignup a{
    color: black;
}

.lsep {
    border: 2px solid lightgray; /* Default border */
    transition: border-color 0.3s ease;
}

.lsep.focused {
    border: 2px solid var(--rfRed); /* Red border when focused */
}

