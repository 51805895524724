.profileComp{
    display: flex;
    /* border: 1px solid black; */
    min-height: 100vh;
    position: relative;
}

.blur{
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.368);
    backdrop-filter: blur(10px);
    font-size: 2rem;
    color: black;
    font-weight: bold;
}

.blur a{
    color: blue;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: underline;
    margin-top: 10px;
}

.blur button{
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--rfRed);
    border: 1px solid var(--rfRed);
    color: white;
    cursor: pointer;
}

.profSidebar{
    /* border: 1px solid black; */
    width: 18%;
    padding: 20px 40px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16vh;
    position: relative;
}

.psTop{
    /* border: 1px solid black; */
    display: flex;
    align-items: start;
    /* justify-content: center; */
    gap: 20px;
    font-weight: 600;
    font-size: 1.1rem;
    width: 100%;
    color: black;
}

.psTop img{
    width: 35px;
    /* height: 50px; */
}

.psComps{
    /* border: 1px solid black ; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.psc{
    border: 2px solid black;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px 12px;
    border-radius: 7px;
    width: 100%;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    color: black;
    font-size: 0.9rem;
}

.pscSelect{
    border: 2px solid var(--rfRed);
    color: var(--rfRed);
}

.psc .img{
    border: 1px solid black;
    height: 25px;
    width: 25px;
}

.pfBottom{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: absolute;
    bottom: 20px;
}

.pfBottom .pfb{
    border: 2px solid lightgray;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.pfBottom p{
    color: #777777;
    font-weight: 600;
    font-size: 0.9rem;
}

/* ProfMain  */
.profMain{
    /* border: 1px solid black; */
    width: 82%;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
}


/* Dashboard  */
.dashboardMain{
    /* border: 1px solid black; */
    /* padding: 30px; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 95%;
    background-color: white;
    border-radius: 20px 0 0 20px;
}

.dsLeft{
    /* border: 1px solid black; */
    width: 30%;
    height: 90%;
}

.dsDate{
    font-size: 5rem;
    font-weight: 100;
    color: #777777;
}

.dsDay{
    font-weight: 400;
    color: #777777;
}

.allStatus{
    /* border: 1px solid black;  */
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.ptp{
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    cursor: pointer;
}

.ptp input{
    display: none;
}

.ptp label{
    border: 1px solid #777777   ;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: red;
}

.ptp p{
    color: red;
    font-weight: 500;
    font-size: 0.9rem;
}

.ptp .ipBox{
    /* border: 1px solid black; */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 2px lightgray;
    width: 200px;
    /* height: 120px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    background-color: white;
    /* left: -100px; */
    top: 25px;
    z-index: 10;
}

.ipBox .ipbColors{
    /* border: 1px solid black; */
    display: flex;
    gap: 10px;
    align-items: center;
    /* justify-content: center; */
}

.ipBox textarea {
    border: 1px solid #777777;
    border-radius: 10px;
    padding: 10px;
    display: block;
    width: 100%;
    white-space: normal; 
    box-sizing: border-box; 
    resize: vertical; 

    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    
      scrollbar-width: none;
  }

  .ipbColors div{
    cursor: pointer;
  }

.ipbg{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: green;
    border: 1px solid green;
}

.ipbr{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: red;
    border: 1px solid red;
    /* margin: 0 10px; */
}

.ipby{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: yellow;
    border: 1px solid yellow;
}

.ipbc{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: gray;
    border: 1px solid gray;
    font-size: 0.6rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
}

.ipBox button{
    background-color: #10a2d8;
    border: 1px solid #10a2d8;
    color: white;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
}

.som{
    border: 2px solid  #b7b7b7;
    padding: 10px;
    border-radius: 10px;
    margin-top: 50px;
}

.som p, .rdu p{
    color: #777777;
    font-weight: 700;
    font-size: 0.9rem;
}

.somTime{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fileInput input{
    display: none;
}

.somTime label{
    font-size: 0.9rem;
    color: #777777;
    font-weight: 500    ;
}

.somTime button{
    padding: 10px 15px;
    font-weight: 600;
    color: #d8d8d8;
    border: 2px solid #d8d8d8;
    background-color: white;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
}

.rdu{
    border: 2px solid #b7b7b7;
    border-radius: 10px;
    padding: 17px 10px;
    margin-top: 10px;
}

.addCmt{
    border: none;
    border-bottom: 2px solid #b7b7b7;
    width: 100%;
    padding: 10px;
    margin: 30px 0;
    color: #777777;
}

.addCmt::placeholder{
    font-weight: 500;
    color: #777777;
}

.fileInput{
    border: 2px solid #82adbe;
    display: flex;
    align-items: center;
    padding: 25px 20px;
    border-radius: 15px;
    gap: 15px;
    background-color: #eff6ff;
}

.fi p{
    color: #777777;
    font-size: 0.9rem;
    font-weight: 700;
}

.fi p:nth-child(2)
{
    color: #10a2d8;
    margin-top: 5px;
}

.dsRight{
    /* border: 1px solid black; */
    width: 50%;
    height: 90%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.reqRes{
    border: 2px solid #fbde92;
    padding: 35px 30px;
    background-color: #fffce9;
    font-weight: 700;
    color: #777777;
    font-size: 1.2rem;
    border-radius: 20px;
    cursor: pointer;
}

.rrBox{
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0;
   top: 0;
   background-color: rgba(255, 255, 255, 0.497);
   backdrop-filter: blur(7px);
}

.rrbClose{
    background-color: gray;
    color: white;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 0.6rem;
    border-radius: 50%;
    position: absolute;
    /* right: 40px;
    top: 40px; */
    /* border: 1px solid black; */
    right: 28%;
    cursor: pointer;
    /* margin-right: 0; */
    z-index: 10;
}

.rrb{
     /* border: 1px solid black; */
     padding: 40px;
     border-radius: 10px;
     box-shadow: 5px 5px 15px 0px lightgray;
     display: flex;
     flex-direction: column;
     gap: 10px;
     
     width: 50%;
     height: 50%;
     background-color: white;
}

.rrBox p {
    cursor: pointer;
    position: relative; /* Add position relative to position the ::before element correctly */
    transition: color 0.3s ease-in-out; /* Optional: transition for text color */
    /* border: 1px solid black; */
  }
  
  .rrBox p::before {
    content: '';
    position: absolute;
    bottom: 50%; /* Center the line vertically */
    left: 0; /* Start from the left */
    background-color: black;
    height: 1px;
    width: 0;
    transition: width 0.3s ease-in-out; /* Transition for width */
  }
  
  .rrBox p.line-through::before {
    width: 100px; /* Full width when clicked */
  }

.timeline{
    border: 2px solid #777777;
    height: 230px;
    border-radius: 20px;
    width: 100%;
}

.ltsUpdate{
    border: 2px solid #777777;
    border-radius: 20px;
    padding: 20px;
}

.ltsUpdate p{
    color: #777777;
}

.ltsUpdate p:nth-child(1)
{
    font-size: 0.9rem;
    font-weight: 700;
}

.ltsUpdate p:nth-child(2){
    font-size: 2.5rem;
    font-weight: 200;
    margin-top: 5px;
    margin-bottom: 20px;
    color: #9a9a9a;
}

.ltsUpdate p:nth-child(3){
    font-size: 0.9rem;
    font-weight: 500;
    width: 85%;
}