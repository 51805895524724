/* .services nav{
    background-color: black;
} */

.smain1{
    background-color: #1C1C1C;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* border: 1px solid white; */
    padding: 0 50px;
}

.allrdm{
    width: 480px;
}

.rdm2{
    /* border: 1px solid white; */
    margin: 30px 0;
}



.red-ani{
    /* border: 1px solid white;  */
    /* width: 480px; */
    width: 0;
    transition: 0.5s ease-out;
    height: 100px;
    /* background-color: white; */
    background-image: linear-gradient(to right, #5f5f5f, 10%, #8f8f8f, 70%,var(--rfRed), 100%, var(--rfRed));
}

.red-ani2{
    /* border: 1px solid white;  */
    width: 0;
    height: 100px;
    transition: 0.5s ease-out;
    /* background-color: white; */
    background-image: linear-gradient(to right, #5f5f5f, 50%,   #131313);
}

.red-ani3{
    /* border: 1px solid white;  */
    width: 0;
    height: 100px;
    transition: 0.5s ease-out;
    /* background-color: white; */
    background-image: linear-gradient(to right, #5f5f5f, 40%,   #131313);
}

.whiteGlow{
    width: 7px;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 20px 0px white;
}

.rdm p{
    margin: 5px 0;
    color: white;
}

.servHead{
    /* border: 1px solid white; */
    /* width: 50%; */
    color:#8f8f8f;
}

.servHead h1{
    font-size: 4.5rem;
    font-weight: 500;
}

.servHead h2{
    font-size: 2.4rem;
    font-weight: 500;
    margin: 20px 0;
}

/* What We Create */
.wwc{
    /* border: 1px solid white; */
    background-color: #1C1C1C;  
}

.wwc h1{
    background-color: #1C1C1C;
    color: #5f5f5f;
    font-weight: 500;
    font-size: 2rem;
    padding: 50px ;
}

.crtns{
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    padding-left: 15%;
}

.crtns a{
    font-size: 1.8rem;
    margin: 2px 0;
    display: flex;
    color: #5f5f5f;
    font-weight: 500;
    transition: 0.2s ease-in;
    align-items: center;
}

.crtns a:hover{
    color: white;
}

.new{
    margin: 0 10px;
    border: 1px solid var(--rfRed);
    color: var(--rfRed);
    font-size: 0.8rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    height: max-content;
    width: max-content;
    border-radius: 30px;
}

.crtns a:hover .new{
    box-shadow: 0px 0px 7px 0px var(--rfRed);
}

/* Projects in Spotlight  */
.smain2{
    background-color: #1C1C1C;
}

.smain2 .wwc{
    padding-bottom: 100px;
}

.prjts{
    /* border: 1px solid white; */      
    background-color: #1C1C1C;
    width: 89.2%;
    /* width: 400px; */
    height: 500px;
    /* background-color: white; */
    display: flex;
}

.prjt1{
    background-image: linear-gradient(105deg, black, #8f8f8f);
    width: 5%;
    height: 100%;
}

.prjt2{
    background-image: linear-gradient(105deg, black, #8f8f8f);
    width: 10%;
    height: 100%;
}

.prjt3{
    background-image: linear-gradient(105deg, black, #8f8f8f);
    width: 15%;
    height: 100%;
}

.prjtLinks{
    width: 55%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1C1C1C;
}

.prjt4{
    width: 49.3%;
    height: 100%;
    position: relative;
}

.prjt4 img, .prjt4 video{
    height: 100%;
    width: 92%;
    /* width: 400px; */
    /* height: 650px; */
    position: absolute;
    /* object-position: top; */
    /* aspect-ratio: 3/2; */
    /* z-index: -1; */
}

.prjt-hide{
    background-color: black;
    height: 100%;
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    opacity: 0;
    transition: 0.2s ease-out;
    padding: 0 30px;
}

.prjt-hide:hover{
    opacity: 0.7;
}

.quality{
    /* border: 1px solid white; */
    display: flex;
    color: white;
    width: 80%;
    margin: 50px auto;
    align-items: flex-start;
    justify-content: center;

}

.quality img{
    width: 500px;
}

.quality p{
    width: 420px;
    font-weight: 300;
    padding: 20px 0;
    margin: 0 60px;
}

.loadMore{
    /* border: 1px solid white; */
    background-image: linear-gradient(300deg, black, 75%, #8f8f8f);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 20%;
    margin-left: 10px;
    cursor: pointer;
}

.loadMore img{
    width: 40%;
}

.loadMore{
    /* border: 1px solid black; */
}

