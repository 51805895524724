.ftlin{
    /* width: 100%; */
    height: 70px;
    /* background-color: var(--rfRed); */
    background-image: linear-gradient(to bottom, #1C1C1C, 70%, var(--rfRed));
    border: none;
}

.footer{
    
    background-color: #1C1C1C;
}

.footerMain{
    width: 80%;
    /* border: 1px solid white; */
    /* height: 100px; */
    margin: auto;
    padding: 30px 0;
    color: white;
}

.rfc{
    /* border: 1px solid white; */
    color: white;
    display: flex;
    align-items: center;
    width: max-content;
    font-size: 0.7rem;
    margin: 20px 0;
}

.rfc img{
    width: 15px;
    margin-right: 15px;
}

.allfs{
    /* padding: 20px 0; */
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

.fs{
    width: 25%;
}

.f{
    /* border: 1px solid white; */
    /* width: 25%; */
    width: max-content;
    padding-bottom: 20px;
}

.f h4{
    margin-bottom: 20px;
}

.f p, .cc, .India{
    font-size: 0.7rem;
    margin: 10px 0;
    cursor: pointer;
}

.ff{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.India{
    font-weight: bold;
}