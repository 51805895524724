.rainbowMain{
    width: 100%;
    margin-top: 15vw;
    /* border: 1px solid black; */
}

.rainbowCon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hSHeading{
    margin-bottom: 0px;
} 

.rainoutimg{
    width: 55%;
    height: auto;
}

.rainContent{
    border-radius: 20px;
    width: 38%;
    height: auto;
    border: 1px solid rgb(251 218 156 / 40%);
    /* border: 1px solid rgba(251, 218, 156, 0.826); */
    /* border: 1px solid transparent; */
    padding: 8px;
    position: absolute;
}

.rainContent{
    border-radius: 35px;
    background-color: white;
    /* position: relative; */
}

.logohead p{
    font-size: 1.7rem;
    font-weight: 600;
}

.raininimg{
    display: flex;
    width: 100%;
    height: auto;
}

.raininCon{
    width: 80%;
    /* border: 1px solid black; */
    padding: 50px 30px 30px 30px;
    display: flex;
    background: transparent;
    margin-top: -340px;
    position: absolute;
    flex-direction: column;
    gap: 35px;
}

.logohead{
    display: flex;
    align-items: center;
    color: white;
    gap: 30px;
}

.raingimg{
width: 16.5%;
}

.logopara p{
    color: white;
    width: 75%;
    font-size: 1rem;
}

.logobtns{
    display: flex;
    gap: 30px;
}

.logobtns .learnmore, .signup{
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 16px;
    justify-content: center;
    gap: 10px;
    color: white;
    font-size: 0.9rem;
    padding: 12px 14px;
    cursor: pointer;
}

.learnmore{
    background: transparent;
}

.signup{
    color: black;
    background: white;
    font-weight: 600;
}

.learnmore img, .signup img{
    width: 15%;
    height: auto;
}

.signup img{
    filter: grayscale(100%) invert(100%);
    transform: rotate(45deg);
}