.StrategyMain{
    background-color: #1C1C1C;
}

.StrtHead{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.StrtImg{
    width: 100%;
}

.StrtHead p{
    color: white;
    text-align: center;
    width: 450px;
    font-weight: 200;
    margin: 50px auto;
    /* border: 1px solid white; */
}

.StrtHead .lm {
    background-color: transparent;
}

.StrtHead .lm-btn{
    margin-bottom: 20px;
}

/* Key Features */
.whiteGlow{
    box-shadow: 3px 0px 20px 1px white;
}


.all-feats{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 60px;
    background-color: black;
    color: white;
    width: 100%;
    /* margin-bottom: 50px; */
}

.af{
    height: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid white; */
    /* width: 16%; */
    cursor: pointer;
}

.af p{
    font-weight: 300;
    font-size: 0.9rem;
    margin-left: 25px;
}

.af:hover .afp{
    filter: drop-shadow(0px 0px 5px white);
}

.allFtImgs{
    /* border: 1px solid black; */

}

.afi{
    /* border: 1px solid white; */
    width: 100%;
    /* margin: 100px auto; */
    display: flex;
    background: linear-gradient(180deg, #585858 0%, #1C1C1C 100%) 0% 0% no-repeat padding-box;
    height: 80vh;
    align-items: center;
    justify-content: center;
    transition: top 0.3s ease, z-index 0.3s ease;
}

.afidesc{
    width: 30%;
}

.afiImg{
    text-align: center;
    width: 20%;
    margin: 0 50px;
}

.afi img{
    width: 100%;
}

.afi h2{
    color: white;
    font-weight: 500;
    font-size: 2rem;
    margin: 20px 0;
}

.afi p{
    color: #BEBEBE;
}

.wfy{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
}

.wfy img{
    width: 40%;
    margin-right: 30px;
}

.wfyt p{
    color: white;
    font-size: 2rem;
    font-weight: 500;
}

.wfyt button{
    background-color: transparent;
    padding: 15px 30px;
    color: #5f5f5f;
    border: 1px solid #5f5f5f;
    width: 300px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 50px;
    margin-top: 40px;
    cursor: pointer;
}

.wfyt button:hover{
    background-color: #212121;
}

.strt-btn{
    margin: 0;
}

.cu{
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;
    justify-content: center;
}

.cuImg{
    width: 300px;
    height: 300px;
    margin-right: 30px;
}

.dsd{
    margin-top: 50px;
}

.wwc{
    /* padding: 50px; */
}

:root {
	--card-height: 40vw;
	--card-margin: 4vw;
	--card-top-offset: 1em;
	--numcards: 4;
	--outline-width: 0px;
}

#cards {
	padding-bottom: calc(var(--numcards) * var(--card-top-offset)); /* Make place at bottom, as items will slide to that position*/
	margin-bottom: var(--card-margin); /* Don't include the --card-margin in padding, as that will affect the scroll-timeline*/
}

#card_1 {
	--index: 1;
}

#card_2 {
	--index: 2;
}

#card_3 {
	--index: 3;
}

#card_4 {
	--index: 4;
}

.card {
	position: sticky;
	top: 100px;
	padding-top: calc(var(--index) * var(--card-top-offset));
}

@supports (animation-timeline: works) {

	@scroll-timeline cards-element-scrolls-in-body {
		source: selector(body);
		scroll-offsets:
			/* Start when the start edge touches the top of the scrollport */
			selector(#cards) start 1,
			/* End when the start edge touches the start of the scrollport */
			selector(#cards) start 0
		;
		start: selector(#cards) start 1; /* Start when the start edge touches the top of the scrollport */
		end: selector(#cards) start 0; /* End when the start edge touches the start of the scrollport */
		time-range: 4s;
	}

	.card {
		--index0: calc(var(--index) - 1); /* 0-based index */
		--reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
		--reverse-index0: calc(var(--reverse-index) - 1); /* 0-based reverse index */
	}
	
	.card__content {
		transform-origin: 50% 0%;
		will-change: transform;

		--duration: calc(var(--reverse-index0) * 1s);
		--delay: calc(var(--index0) * 1s);

		animation: var(--duration) linear scale var(--delay) forwards;
		animation-timeline: cards-element-scrolls-in-body;
	}

	@keyframes scale {
		to {
			transform:
				scale(calc(
					1.1
					-
					calc(0.1 * var(--reverse-index))
				));
		}
	}
}

/** DEBUG **/

#debug {
  position: fixed;
  top: 1em;
  left: 1em;
}
#debug::after {
  content: " Show Debug";
  margin-left: 1.5em;
  color: white;
  white-space: nowrap;
}

#debug:checked ~ main {
  --outline-width: 1px;
}

#cards {
	list-style: none;
	outline: calc(var(--outline-width) * 10) solid blue;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(var(--numcards), var(--card-height));
	gap: var(--card-margin);
}

.card {
	outline: var(--outline-width) solid hotpink;
}

.card__content {
	box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
	background: rgb(255, 255, 255);
	color: rgb(10, 5, 7);
	border-radius: 1em;
	overflow: hidden;

	display: grid;
	grid-template-areas: "text img";
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;

	align-items: stretch;
	outline: var(--outline-width) solid lime;
}

.card__content > div {
	grid-area: text;
	width: 80%;
	place-self: center;
	text-align: left;

	display: grid;
	gap: 1em;
	place-items: start;
}

.card__content > figure {
	grid-area: img;
	overflow: hidden;
}

.card__content > figure > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.btn {
	background: rgb(188 87 36);
	color: rgb(255 255 255);
	text-decoration: none;
	display: inline-block;
	padding: 0.5em;
	border-radius: 0.25em;
}

aside {
	width: 50vw;
	margin: 0 auto;
	text-align: left;
}

aside p {
	margin-bottom: 1em;
}