.RfSliderMain{
    border: 1px solid black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    margin-top: 5%;
    overflow-x: hidden;
}

.RfSliderBtns{
    border: 1px solid black;
    position: absolute;
    width: 90%;
    text-align: left;
}

.RfSliderWindow{
    border: 1px solid black;
    width: 80%;
    height: 350px;
    /* overflow-x: hidden; */
    position: relative;
}

.RfSlider{
    border: 1px solid black;
    height: 100%;
    display: flex;
    align-items: center;
    /* width: 100%; */
    width: 1860px;
    gap: 20px;
    /* margin-left: -100px ; */
    transition: 0.3s ease-in-out;
}

.RfSlide{
    /* border: 1px solid black; */
    height: 100%;
    width: 270px;
    /* margin: 0 20px; */
}