a{
    text-decoration: none;
    color: white;
}

select, input, textarea{
    outline: none;
    cursor: pointer;
}

nav {
    /* position: sticky; */
    background-color: #000000b3;
    backdrop-filter: blur(10px);
    color: white;
    padding: 0 20px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0px 0px 10px 3px #0000001C;
}

.navbar-toggler {
    display: none;
  }

.rfLogo img{
    width: 20px;
    margin-right: 20px;
}

.rfLogo{
    display: flex;
    align-items: center;
}

.navComp{
    display: flex;
    list-style: none;
    /* border: 1px solid white; */
    width: 35%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-weight: 600;
}

.navComp img{
    width: 20px;
}

.navComp li{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.desBox{
    /* border: 1px solid red; */
    width: max-content;
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.des{
    /* border: 1px solid black; */
    /* transition: 0.5s ease-in-out; */
    animation: design ease-in-out infinite 15s;
    margin-bottom: -50px;
}

.de{
    /* border: 1px solid black; */
    /* padding: 0 10px; */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@keyframes design {
    0%{
        margin-bottom: 50px;
        opacity: 0;
    }
    2%{
        margin-bottom: 0px;
        opacity: 1;
    }
    17%{
        margin-bottom: 0px;
        opacity: 1;
    }
    19%{
        margin-bottom: -50px;
        opacity: 1;
    }
    36%{
        margin-bottom: -50px;
    }
    38%{
        margin-bottom: -100px;
    }
    55%{
        margin-bottom: -100px;
    }
    57%{
        margin-bottom: -150px;
    }
    74%{
        margin-bottom: -150px;
    }
    76%{
        margin-bottom: -200px;
    }
    93%{
        margin-bottom: -200px;
    }
    95%{
        margin-bottom: -250px;
        opacity: 1;
    }
    100%{
        opacity: 0;
        /* margin-bottom: 0px; */
    }
}


/* Hmain1 */
.hmm{
    /* background-color: black; */
    background: transparent linear-gradient(139deg, #FFFFFF 0%, #FFCCCC 100%) 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden !important;;
}

.hmain1{
    /* height: 100vh; */
    /* background-color: black; */
    display: flex;
    align-items: center;
    /* border: 1px solid white;  */
    width: 80%;
    position: relative;
    /* overflow: hidden; */
}

.hmLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid white; */
    /* height: 50vh; */
    width: 50%;
}

.hmLeft img{
    /* border: 1px solid white; */
    width: 150px;
    /* margin: 0 100px; */
}

.hmRight{
    /* margin-top: -20px; */
    /* border: 1px solid white; */
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vw;
    width: 70vw;
}

.homeHero{
    /* border: 1px solid white; */
    object-fit: cover;
    width: 72vw;
    /* height: 70vh; */
    aspect-ratio: 3/2;
    position: absolute;
    right: -250px;
    top: -150px;
}


.hmTitle{
    color: black;
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: 400;
    /* line-height: 4.5rem; */
    margin: 0;
}

.hmDesc{
    /* border: 1px solid white;     */
    color: black;
    width: 500px;
    font-size: 1.2rem;
    /* margin: 50px 0; */
    margin-top: 20px;
    margin-bottom: 50px;
}

.rfRed{
    color: var(--rfRed);
}

.rfRed span{
    color: var(--rfRed);
    font-weight: 900;
    font-style: italic;
}

.rfBr{
    font-weight: 800;
}


.lm{
    background-color: transparent;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
    border: none;
    padding: 20px;
    font-size: 1.1rem;
    cursor: pointer;
}

.arrows img{
    width: 20px;
    transition: 0.3s ease-in-out;
    transform: rotate(90deg);
}

.ex-btn img{
    transform: rotate(0deg);
}

.arrow1{
    position: absolute;
    margin-top: -30px;
    opacity: 0;
    /* border: 1px solid red; */
}

.ex-btn .arrow1{
    margin-top: 0;
    margin-left: -30px;
}

.arrow2{
    position: relative;
    opacity: 1;
}

.arrows{
    /* border: 1px solid white; */
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.lm-btn:hover .arrow1{
    cursor: pointer;
    opacity: 1;
    margin-top: -10px;
}

.lm-btn:hover .arrow2{
    margin-bottom: -40px;
    opacity: 0;
}

.ex-btn:hover .arrow1{
    margin-top: 0;
    cursor: pointer;
    opacity: 1;
    margin-left: 10px;
}

.ex-btn:hover .arrow2{
    margin-bottom: 0;
    margin-left: 0px;
    opacity: 0;
}

.lm-btn{
    width: max-content;
}   

.btn-r, .btn-w{
    width: 100%;
    height: 2px;
    background-color: var(--rfRed);
    transition: 0.3s ease-in-out;
}

.btn-w{
    width: 0;
    margin-top: -2px;
    background-color: black;
}

.lm-btn:hover .btn-w{
    width: 100%;
}




/* Hmain2 */
.nth{
    background-color: #1C1C1C;
    color: white;
    padding: 30px;
}

.no-ord{
    color: #5f5f5f;
    font-size: 5.5rem;
    font-weight: 900;
    line-height: 110px;
}

.noth{
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(to bottom, #5f5f5f, #5f5f5f, black);
}

.ord{
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(to bottom, var(--rfRed), var(--rfRed), black);
}

.provides{
    /* border: 1px solid white; */
    width: 80%;
    margin: 50px auto;
}

.p{
    margin: 30px 0;
}

.right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pCont{
    /* border: 1px solid white; */
    width: 50%;
    display: flex;
    align-items: center;
}

.pCont img{
    width: 170px;
    /* aspect-ratio: 2/2;
    object-fit: contain; */
}

.right img{
    width: 140px;
}


.pdesc h2{
    color:#5f5f5f;
    margin: 10px 0; 
}

.em-btn{
    /* border: 1px solid white; */
    margin: 100px auto;
    /* width: 190px; */
}

.em{
    background-color: transparent;
}

.em-arr{
    /* border: 1px solid white; */
    width: max-content;
}

.em-arr img{
    transform: rotate(-90deg);
}

.em-arr .arrow1{
    margin-top: 0;
    margin-left: -40px;
}

.em-arr .arrow2{
    margin-top: 0;
    right: 0;
}

.em-btn:hover .arrow1{
    cursor: pointer;
    opacity: 1;
    margin-left: 0px;
    /* margin-right: 10px; */
    margin-top: 0;
}

.em-btn:hover .arrow2{
    margin-bottom: 0;
    right: -20px;
    opacity: 0;
    /* position: absolute ; */
    /* display: none; */
}

/* High Volume Clients */

.hvc h1{
    font-weight: normal;
    color: #5f5f5f;
    font-size: 2.5rem;
}

.clients{
        /* border: 1px solid white; */
        width: 80%;
        margin: 200px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.c{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.c .shadow{
    /* position: absolute; */
    height: 20px;
    width: 100%;
    /* background-color: #5f5f5f;    */
    /* background-image: linear-gradient(to bottom, white, #5f5f5f); */
    margin-top: -20px;
    position: relative;
    
}

.cin{
    width: 80%;
    /* height: 120px; */
    margin: auto;
    position: relative;
    box-shadow: 0px 0px 25px 20px #1C1C1C;
    /* border: 1px solid white; */
    top: -205px;
}


.clients img{
    width: 120px;
    aspect-ratio: 3/2;
    object-fit: contain;
}

/* Our Strategy */
.osImg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
}

.osImg img{
    width: 70%;
}

/* Custom Solutions */
.org{
    background-color: var(--rfRed);
    width: 60%;
    height: 300px;
    margin: auto;
    margin-top: 50px;
}

/* What's New  */
.orgs{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 50px;
}

.wno{
    width: 240px;
    height: 330px;
    background-color: var(--rfRed);
}

/* navbar css */
/* .desBox {
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    height: 20px; 
  }
  
  .des {
    position: relative;
    height: 100%;
  }
  
  .de {
    font-size: 14px;
    position: absolute;
    white-space: nowrap;
    animation: fade-in-out 2s infinite;
  } */
  
  