.solution{
    /* padding: 50px 0; */
}

/* Solmain  */
.solmain{
    /* border: 1px solid white; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
}

.solmain h1{
    font-size: 7rem;
    font-weight: 500;
    width: 60%;
    /* margin: auto; */
    /* border: 1px solid white; */
    color: #8f8f8f;
    padding-bottom: 0;
}

.sol-h2{
    color: white;
    font-weight: 500;
    font-size: 2rem;
    /* border: 1px solid white; */
    box-shadow: 0px -20px 50px 20px #1C1C1C;
    width: 100%;
    text-align: center;
    /* margin-top: -75px; */
    /* margin-top: 100px; */
    position: absolute;
    transition: 0.3s ease;
    /* top: -75px; */
    /* top: 200px; */
    /* bottom: -50px; */
    /* bottom: 160px; */
}

/* solmain2  */
.solmain2{
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}


.solb{
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    color: white;
    width: 65%;
    margin: 50px auto;
    font-weight: 300;
}

.solb img{
    width: 250px;
    margin-right: 70px;
}

/* solmain3 */
.solmain3{
    background-color: #1C1C1C;
    height: 100vh;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    /* padding: 100px 0; */
    /* padding-top: 200px; */
}

.ssc{
    /* border: 1px solid white; */
    display: flex;
    color: white;
    width: 55%;
    margin: auto;
}

.ssc-d{
    border: 1px solid #5F5F5F;
    padding: 15px;
    font-size: 1.2rem;
    margin: 0 30px;
    box-shadow: 0px 10px 10px 0 #0000006E;
    cursor: pointer;
}

.sbi{
    border: 1px solid #5F5F5F;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 0 10px;
    box-shadow: 0px 10px 10px 0 #0000006E;
    cursor: pointer;
}

.sbi:hover img{
    transform: scale(1.1);
}

.sb-icons{
    /* border: 1px solid white; */
    display: flex;
    width: 50%;
    padding: 50px 0;    
    margin: auto;
    position: relative;
    z-index: 1;
}

.sb-icons img{
    /* border: 1px solid white; */
    width: 60px;
    aspect-ratio: 3/2;
    object-fit: contain;
    transition: 0.1s ease-in;
}


.glitch-container {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    /* border: 1px solid white; */
    transition: 0.5s ease;
    
  }

  .subDesc{
    position: relative;
    /* border: 1px solid white; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 100px; */
  }

  /* .GlitchedImgs{
    position: relative;
    border: 1px solid white;
  } */


  .allGlitches{
    animation: 2s ease moveUpDown infinite;
    position: relative;
    /* border: 1px solid white; */
    width: max-content;
    z-index: 1;
    transform: scale(0.8);
    transition: 0.3s ease;
  }

  .st-db{
    color: #419A4B;
  }

  .starter{
    /* border: 1px solid white; */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 150px;
    transition: 0.5s ease-in-out;
    z-index: 1;
    width: 450px;
  } 

  .starter h1{
    font-weight: 500;
    padding: 0;
  }

  .month-sb{
    font-size: 0.8rem;
    margin-top: 5px;
    color: #8f8f8f;
  }

  .starter-desc{
    /* border: 1px solid white; */
    margin: 40px 0;
  }

  .ac-now{
    padding: 10px 20px;
    background-color: #419A4B;
    color: white;
    border: none;
    box-shadow: 0px 20px 20px #00FF1D3B;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: 0.2s ease;
  }

  .ac-now:hover{
    translate: 0px 3px;
    box-shadow: 0px 15px 20px #00FF1D3B;
  }


  @keyframes moveUpDown {
    0%, 100%{
        top: 0;
    }

    50%{
        top: 20px;
    }
    
  }
  
  .glitch-image {
    display: block;
    max-width: 100%;
    height: auto;
    transition: transform 0.2s ease-in-out;
  }
  
  .glitch {
    transform: translate3d(-10px, 10px, 0);
    opacity: 0;
  }
  

  .floor{
    position: absolute;
    width: 100%;
    bottom: -100px;
    /* z-index: -1; */
    /* pointer-events: all; */
  }

/* Support pack */
.sp-icons img{
  width: 35px;
}

.spDesc{
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-top: -50px;
}

.spGlitches, .spBack{
  width: 700px;
  margin-bottom: -30px;
}

.spDesc .starter{
  margin-right: 50px;
  margin-top: 70px;
}

.sp{
  height: 90vh;
}